import './App.css';

function App() {
  return (
    <div>
<section className="container-fluid bg-color">
  <div className='row justify-content-center'>
    <div className='col-md-10'>
      <h2 className=' mt-3 d-flex align-items-center justify-content-center'><img src="assests/ArifAi-logo.png" className="logo mr-2" alt='cogmatic'/>Arif AI LLC</h2>
      <div>
        <p>I am Arif, a Senior Solutions Architect with over a decade of experience in software development and AI technologies. My academic journey began with a Bachelor's in Computer Science Engineering, followed by an MSc in Machine Learning from Royal Holloway. I furthered my expertise with specialized studies in AI and Edge Computing at the University of Oxford.</p>
        <p>Throughout my career, I have been dedicated to pioneering innovative solutions and leading technology initiatives. I am a co-founder of Cogmatic and Orbix NDE, companies at the forefront of technological innovation. My focus has always been on leveraging my deep technical expertise to deliver practical, cutting-edge solutions that offer a competitive advantage. This approach has not only fueled my passion for technology but also allowed me to contribute to real-world innovation and excellence.</p>
        <p>I am driven by the challenge of solving complex problems and the continuous pursuit of learning and development. I look forward to connecting with like-minded professionals and exploring new opportunities to drive technological advancement.</p>
      </div>
      
      {/* Companies */}
      <div className='row flex-wrap justify-content-center mt-4'>
        <div className="col-lg-4 mb-4">
          <div className="shadow-sm box-size rounded border p-3">
            <a href="https://cogmatic.ai" target="_blank" rel="noopener noreferrer">
              <img src="assests/cogmatic-logo.png" className="company-logo" alt='cogmatic'/>
            </a>
            <p className='pt-2'>Cogmatic develops bespoke AI Solutions and Enterprise Software. We are experts at bringing custom machine learning solutions out of the lab and into the real world.
            </p>
            <a href="https://cogmatic.ai" target="_blank" rel="noopener noreferrer" className='link'>
              Learn more <i className="bi bi-arrow-right-short"></i>
            </a>
          </div>
        </div>
        <div className="col-lg-4 mb-4">
          <div className=" shadow-sm box-size rounded border p-3">
            <a href="https://www.orbixnde.com" target="_blank" rel="noopener noreferrer">
              <img src="assests/orbix-logo.png" className="company-logo" alt='cogmatic'/>
            </a>
            <p className='pt-2'>Orbix NDE Inc offers a revolutionary cloud platform that facilitates digital transformation with comprehensive digital NDE workflows and impactful AI functionality. 
            </p>
            <a href="https://www.orbixnde.com" target="_blank" rel="noopener noreferrer" className='link'>
              Learn more <i className="bi bi-arrow-right-short"></i>
            </a>
          </div>
        </div>
        <div className="col-lg-4 mb-4">
          <div className="shadow-sm box-size rounded border p-3">
            <a className='a-custom' href="/" onClick={() => alert('Website under development')}>
              <h4 className="mb-0">
                A<span className="header-custom">daptive</span>R<span className="header-custom">eal-time </span>I<span className="header-custom">ntelligence </span>F<span className="header-custom">rameworks</span>
              </h4>
            </a>
            <p className="mt-2">ARIF (Adaptive Real-time Intelligence Framework) describes a system or platform designed to analyze data and provide insights in real time, currently in the design phase. This kind of framework can be applied across various industries for different purposes, such as in Healthcare, Manufacturing, Cybersecurity, Autonomous Navigation and Collision Avoidance, Real-time Surveillance and Analysis, Swarm Intelligence etc.
            </p>
          
          </div>
        </div>
      </div>
      <p className='text-center text-muted '>The arif.ai website is currently under development, please visit our Cogmatic website.</p>
    </div>
  </div>
</section>

      <iframe
          src="https://cogmatic.ai"
          title="Cogmatic AI"
          style={{ width: '100%', height: '100vh', border: 'none', overflow: 'hidden' }}
        >
          Your browser does not support iframes.
        </iframe>
    </div>
  );
}

export default App;
